/*Configurações Gerais*/

.orange-color {
    color:#EF7A68 !important;
}

.verde-color{
    color: #057683;
}

.marrom-color{
    color: #4A4444;
}

.marrom-secondary {
    color: #85725A;
}

