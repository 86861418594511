@import "@sass/components/navbar";
@import "@sass/components/reset";
@import "@sass/components/banner";
@import "@sass/components/cards";
@import "@sass/components/buttons";
@import "@sass/components/backgrounds";
@import "@sass/components/swiper";
@import "@sass/components/accordion";
@import "@sass/components/footer";
@import "@sass/components/login";
@import "@sass/components/colors";

#especialidades {
  .banner-top-settings {
    background-image: url('/img/servicos/banner.jpg');

    h1 {
      color: #057683;
    }
    p {
      color: #9A9A9A;
    }
  }
  
  .card-especialidades {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #85725A;
    padding: 1rem;
    position: relative;
    text-align: center;
    border-radius: 30px;
    margin-bottom: 2rem;
    transition: all 0.3s ease;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      // background-color: var(--orange-HC-color);

      .element-pata {
        transform: translateY(-5px);
      }

      // .title, .subtitle {
      //   color: var(--white-HC-color);
      // }
      .fab {
        color: var(--white-HC-color);
      }
    }

    .element-pata {
      position: absolute;
      transition: all 0.3s ease;
      left: -31px;
      top: -5px;
    }
    .title {
      color: #85725A;
      font-size: 20px;
      font-family: "Mitr", sans-serif;
      font-weight: 500;
      width: min-content;
    }
    .subtitle {
      color: #F3A94C;
      text-shadow: 0px 3px 6px #BC131329;
      font-size: 16px;
      margin-bottom: 0;
      display: inherit;
      font-family: "Mitr", sans-serif;
      font-weight: 400;
    }

    .fab {
      color: var(--orange-HC-color);
    }
  }
}