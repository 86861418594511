@import "@sass/components/navbar";
@import "@sass/components/reset";
@import "@sass/components/banner";
@import "@sass/components/cards";
@import "@sass/components/buttons";
@import "@sass/components/backgrounds";
@import "@sass/components/swiper";
@import "@sass/components/accordion";
@import "@sass/components/footer";
@import "@sass/components/login";
@import "@sass/components/colors";

#servicos {
    overflow: hidden;
  .banner-top-settings {
    background-image: url('/img/servicos/banner.jpg');

    h1 {
      color: #057683;
    }
    p {
      color: #9A9A9A;
    }
  }

  .card-servicos {
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    margin-bottom: 2rem;
    width: 90%;
    transition: all 0.3s ease;

    &:hover {
      .element-pata {
        transform: translateY(-5px);
      }

      .description-wrapper {
        .subtitle {
          color: var(--orange-HC-color);
        }
      }
    }

    .description-wrapper {
      top: 100%;
      background-color: var(--white-HC-color);
      padding: 28px;
      border-radius: 30px;
      width: 80%;
      border: 1px solid #057683;
      text-align: center;

      .dropdown-toggle {
        background-color: #EF7A68;
        color: white;
      }

      .element-pata {
        position: absolute;
        transition: all 0.3s ease;
        left: -31px;
        top: -5px;
      }
      .title {
        color: #057683;
        font-size: 20px;
        font-family: "Mitr", sans-serif;
      }
      .subtitle {
        color: var(--egyptian-HC-Color);
        font-size: 16px;
        margin-bottom: 0;
        display: inherit;
        font-family: "Mitr", sans-serif;
        font-weight: 400;
      }
    }

    .fab {
      color: var(--orange-HC-color);
    }
}
}
