.color-footer {
	background-color: #0B3C41;
	padding-top: 3.5rem;
	font-size: 0.8rem;
	text-align: start;
    .brand-logo {
        @media (max-width: 767.98px){
            width: 300px;
        }
    }

	a {
		color: white;
		text-decoration: none;
		line-height: 25px;

		&:hover {
			color: #EF7A68;
		}
	}

	p {
		line-height: 25px;
		word-break: break-word;
	}
}

.bg-footer {
	transform: translateY(40px);
}
