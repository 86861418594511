@import "@sass/components/navbar";
@import "@sass/components/reset";
@import "@sass/components/banner";
@import "@sass/components/cards";
@import "@sass/components/buttons";
@import "@sass/components/backgrounds";
@import "@sass/components/swiper";
@import "@sass/components/accordion";
@import "@sass/components/footer";
@import "@sass/components/login";
@import "@sass/components/colors";

#contato {
  .banner {

    h1 {
      color: #fff;
      font-weight: bold;
      text-align: center;
    }

    p {
      text-align: center;
      color: #fff;
    }
  }

  h2 {
    color: #85725A;
  }

  p {
    
    color: #4A4444;  
  }

  input {
    padding: 20px;
    border-radius: 8px;
    border-color: #81A2B8;
  }

  textarea {
    border-radius: 8px;
    border-color: #81A2B8;
  }
}