@charset "UTF-8";
:root {
  --color-white: #fff;
  --color-light: hsl(206, 33%, 96%);
  --color-black: #000;
  --color-night: hsl(214, 100%, 10%);
  --color-purple: hsl(291, 64%, 42%);
  --color-indigo: #057683;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  	0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  	0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.brand-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}
.brand-link span {
  color: #98F3AC;
}

.navbar {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  position: fixed;
  width: 100%;
}
.navbar .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.navbar .burger {
  position: relative;
  display: none;
  cursor: pointer;
  width: 2rem;
  height: 1rem;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}
.navbar .burger-line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 0.25rem;
  background: var(--color-black);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.navbar .burger-line:nth-child(1) {
  top: 0;
}
.navbar .burger-line:nth-child(2), .navbar .burger-line:nth-child(3) {
  top: 0.5rem;
}
.navbar .burger-line:nth-child(4) {
  top: 1rem;
}
.navbar .burger.active .burger-line:nth-child(1), .navbar .burger.active .burger-line:nth-child(4) {
  top: 1.25rem;
  width: 0%;
  left: 50%;
}
.navbar .burger.active .burger-line:nth-child(2) {
  transform: rotate(45deg);
}
.navbar .burger.active .burger-line:nth-child(3) {
  transform: rotate(-45deg);
}
@media (max-width: 1199.98px) {
  .navbar .menu {
    width: 100%;
  }
}
.navbar .menu-inner {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar .menu .menu-item {
  display: flex;
  align-items: center;
}
.navbar .menu-link {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  border: none;
  color: #AEAEAE;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease-in-out;
  padding: 10px;
  text-align: center;
}
.navbar .menu-link.active, .navbar .menu-link:hover:not(.orange-color) {
  border: none;
  outline: none;
  color: var(--color-indigo);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .navbar .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
  }
  .navbar .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

main {
  margin-top: 4rem;
}

:root {
  --white-HC-color: #ffffff;
  --orange-HC-color: #ef7a68;
  --green-HC-color: #057683;
  --egyptian-HC-Color: #f3a94c;
  --cheng-HC-color: #e39b2f;
  --light-HC-grey: #f7f7fa;
  --text-color: #85725A;
}

@keyframes puls {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    opacity: 1;
    transform: scale(0.9);
  }
}
.padding-space {
  padding: 3rem 1rem;
}

.banner .swiper {
  height: 76%;
}

.banner-img {
  background-size: cover;
  background-position: center;
  text-align: start;
}

.banner .swiper-button-prev {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  left: -1px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-prev::after {
  font-size: 16px;
  font-weight: bold;
}
.banner .swiper-button-next {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  right: -1px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-next::after {
  font-size: 16px;
  font-weight: bold;
}

.background-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-top-settings {
  padding: 2rem 1rem;
}
.banner-top-settings .subtitle {
  font-size: 18px;
}

.banner-unidades {
  height: 315px;
  display: flex;
  align-items: center;
}

.bg-color-grey {
  background-color: #f4f4f491;
}

@media (max-width: 500px) {
  .banner .swiper-button-next {
    display: none;
  }
  .banner .swiper-button-prev {
    display: none;
  }
}
.card-color-text {
  color: #6d6d6d !important;
}

.up-card {
  transform: translateY(-50px);
  z-index: 9;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  height: 100%;
  padding: 0.5rem;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media (max-width: 526px) {
  .card-unidades .title-wrapper {
    width: 100%;
  }
  .card-unidades .title-wrapper .element-dog {
    max-width: 60px;
  }
  .card-unidades .title-wrapper .title {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .up-card {
    transform: translateY(0);
  }
}
.btn-banner {
  background-color: #057683;
  color: #fff;
  padding: 0.5rem 1rem;
}
.btn-banner:hover {
  color: #ef7a68;
  background-color: #fff;
  border: solid 1px #ef7a68;
  transition: all 0.35s ease;
}

.btn-orange {
  background: #85725a;
  color: #fff;
  padding: 12px 48px;
}
.btn-orange:hover {
  background-color: #fff;
  color: #4a4444;
  border: solid 1px #85725a;
  transition: all 0.35s ease;
}

.whatsapp-button {
  background-color: #ef7a68;
  color: white;
  border-radius: 50px;
  position: fixed;
  bottom: 21px;
  display: flex;
  padding: 10px 20px;
  right: 8px;
  text-align: center;
  color: var(--white-HC-color);
  z-index: 20;
}
.whatsapp-button .btn {
  color: white;
}
.whatsapp-button .btn:focus {
  box-shadow: none;
  color: white;
}
.whatsapp-button .dropdown-menu.show {
  transform: translateY(-82px) !important;
  width: -webkit-fill-available;
  border: none;
}
.whatsapp-button img {
  width: 32px;
  margin-right: 10px;
}
.whatsapp-button span {
  font-size: 14px;
}

.btn__redes {
  display: grid;
  bottom: 20px;
  left: 20px;
  position: fixed;
  z-index: 999;
}

.btn-i {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  color: #c71e7e;
  display: flex;
  transition: all 0.2s ease-in-out;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .btn-i {
    width: 100%;
  }
}
.btn-i iconify-icon {
  color: #df3796;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-i span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-i:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-i:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-i:hover iconify-icon {
    transform: translateX(39px);
  }
}
.btn-i:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-i:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.btn-f {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 30px;
  color: #286ed6;
  display: flex;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 768px) {
  .btn-f {
    width: 100%;
  }
}
.btn-f iconify-icon {
  color: #286ed6;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-f span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-f:hover {
  transform: scale(1.1);
  background-color: #286ed6;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-f:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-f:hover iconify-icon {
    transform: translateX(40px);
  }
}
.btn-f:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-f:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .btn-banner {
    width: 100% !important;
  }
}
.bg-azul {
  background-image: url("/img/quem-somos/img-quem-somos.jpg");
}

.bg-cinza {
  background-color: #f7f7fa;
}

.bg-cinza-2 {
  background-color: #f5f6f6;
}

.bg-verde {
  background-color: #057683;
}

.bg-servicos {
  background-image: url("/img/laranja.png");
}

.bg-cat {
  background-image: url("/img/home/bg-cat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 100%;
  transform: translateY(80px);
  border-radius: 10px;
}

.bg-cardiac {
  background: #ef7a68;
  height: 50%;
}

.bg-footer {
  background-image: url("/img/footer/redes-sociais.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .bg-footer {
    background-image: none;
    background-color: #057683;
  }
}
.swiper {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1rem;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .swiper {
    width: 100%;
  }
}
.wrapper {
  height: 100%;
}

.block__item {
  margin-bottom: 20px;
}

.block__title {
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.block__title::before,
.block__title::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 8px;
  transition: all 0.3s ease 0s;
}

.block__title:before {
  transform: rotate(40deg);
  left: 0;
}

.block__title::after {
  transform: rotate(-40deg);
  left: 8px;
}

.block__title.active::before,
.block__title.active::after {
  background-color: #ef7a68;
}

.block__title.active::before {
  transform: rotate(-40deg);
}

.block__title.active::after {
  transform: rotate(40deg);
}

.block__text {
  display: none;
  padding-top: 10px;
}

.faq-conteudo .accordion-flush .accordion-item .accordion-button {
  border-radius: 10px;
}
.faq-conteudo .accordion-button {
  position: relative;
  display: grid;
  width: 100%;
  padding: 2rem 2.25rem;
  font-size: 1rem;
  color: #4A4444;
  font-family: "Poppins";
  text-align: left;
  background-color: #F5F6F6;
  border: 0;
  border-radius: 10px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  flex-direction: row-reverse;
  align-items: center;
  align-content: stretch;
}
.faq-conteudo .accordion-button::after {
  flex-shrink: 0;
  position: absolute;
  width: 1.25rem;
  left: 12px;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.color-footer {
  background-color: #0B3C41;
  padding-top: 3.5rem;
  font-size: 0.8rem;
  text-align: start;
}
@media (max-width: 767.98px) {
  .color-footer .brand-logo {
    width: 300px;
  }
}
.color-footer a {
  color: white;
  text-decoration: none;
  line-height: 25px;
}
.color-footer a:hover {
  color: #EF7A68;
}
.color-footer p {
  line-height: 25px;
  word-break: break-word;
}

.bg-footer {
  transform: translateY(40px);
}

.img-login {
  background-image: url("/img/pets-banner.png");
  object-fit: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .img-login {
    background-image: none;
  }
}
/*Configurações Gerais*/
.orange-color {
  color: #EF7A68 !important;
}

.verde-color {
  color: #057683;
}

.marrom-color {
  color: #4A4444;
}

.marrom-secondary {
  color: #85725A;
}

.paw-list img {
  width: 2rem;
}

.img-gallery {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.img-gallery a img {
  max-height: 20rem;
  width: 100%;
}

.setas-baixo {
  transform: translateY(100px);
  width: 6rem;
}

.setas-cima {
  transform: translateY(50px);
  width: 6rem;
}

.servicos-wrapper {
  background-repeat: no-repeat;
  background-position: 60px;
  background-color: #fff;
}

.dashboard {
  background-image: url(/img/backgrounds/bg-element-animals.png);
  background-repeat: no-repeat;
  background-position: 60px;
  background-color: var(--light-HC-grey);
}
.dashboard .dashboard-header {
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--white-HC-color);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}
.dashboard .dashboard-header .user-name-title {
  font-size: 18px;
  color: var(--green-HC-color);
  font-family: "Mitr", sans-serif;
  margin-bottom: 0;
  margin-right: 17px;
  word-break: break-word;
  text-align: center;
  position: relative;
}
.dashboard .dashboard-header .user-name-title::before {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 28px;
  top: 0;
  right: -7px;
  background-color: var(--green-HC-color);
}
.dashboard .dashboard-header .user-button-logout {
  background-color: var(--egyptian-HC-Color);
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  color: var(--white-HC-color);
  transition: all 0.3s ease;
  cursor: pointer;
}
.dashboard .dashboard-header .user-button-logout:hover {
  background-color: var(--green-HC-color);
}
.dashboard .dashboard-header .user-button-logout .fa-sign-out-alt {
  font-size: 20px;
}
.dashboard .accordion .accordion-item {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.dashboard .accordion .accordion-item .accordion-header .accordion-button {
  background-color: var(--orange-HC-color);
  color: var(--white-HC-color);
  border-radius: 8px;
}
.dashboard .accordion .accordion-item .accordion-header .accordion-button:focus {
  border-color: #ef7a68;
  box-shadow: 0 0 0 0.25rem #ef7a6840;
}
.dashboard .accordion .accordion-item .accordion-header .accordion-button::after {
  display: none;
}
.dashboard .accordion .accordion-item .accordion-body .description-wrapper {
  padding: 1rem;
  border-bottom: 1px solid #f3a94c;
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper {
  padding: 1rem;
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper .title {
  font-size: 22px;
  color: var(--green-HC-color);
  font-family: "Mitr", sans-serif;
  text-align: center;
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper .data {
  color: var(--green-HC-color);
  margin-bottom: 0;
  padding: 5px 10px;
  background-color: #05768321;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper .link-wrapper {
  color: var(--green-HC-color);
  margin-bottom: 1rem;
  padding: 5px 10px;
  background-color: #05768321;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper .link-wrapper a {
  word-break: break-word;
  color: var(--orange-HC-color);
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper .file-wrapper {
  background-color: var(--light-HC-grey);
  padding: 10px;
  border-radius: 5px;
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper .file-wrapper .file-content {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 1rem;
  background-color: var(--white-HC-color);
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper .file-wrapper .file-content img {
  width: 60px;
}
.dashboard .accordion .accordion-item .accordion-body .information-wrapper .file-wrapper .file-content .btn-download {
  background-color: var(--egyptian-HC-Color);
}

@media (max-width: 600px) {
  .img-gallery {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .dashboard .dashboard-header .user-name-title::before {
    display: none;
  }
}
:root {
  --color-white: #fff;
  --color-light: hsl(206, 33%, 96%);
  --color-black: #000;
  --color-night: hsl(214, 100%, 10%);
  --color-purple: hsl(291, 64%, 42%);
  --color-indigo: #057683;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  	0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  	0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.brand-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}
.brand-link span {
  color: #98F3AC;
}

.navbar {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  position: fixed;
  width: 100%;
}
.navbar .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.navbar .burger {
  position: relative;
  display: none;
  cursor: pointer;
  width: 2rem;
  height: 1rem;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}
.navbar .burger-line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 0.25rem;
  background: var(--color-black);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.navbar .burger-line:nth-child(1) {
  top: 0;
}
.navbar .burger-line:nth-child(2), .navbar .burger-line:nth-child(3) {
  top: 0.5rem;
}
.navbar .burger-line:nth-child(4) {
  top: 1rem;
}
.navbar .burger.active .burger-line:nth-child(1), .navbar .burger.active .burger-line:nth-child(4) {
  top: 1.25rem;
  width: 0%;
  left: 50%;
}
.navbar .burger.active .burger-line:nth-child(2) {
  transform: rotate(45deg);
}
.navbar .burger.active .burger-line:nth-child(3) {
  transform: rotate(-45deg);
}
@media (max-width: 1199.98px) {
  .navbar .menu {
    width: 100%;
  }
}
.navbar .menu-inner {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar .menu .menu-item {
  display: flex;
  align-items: center;
}
.navbar .menu-link {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  border: none;
  color: #AEAEAE;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease-in-out;
  padding: 10px;
  text-align: center;
}
.navbar .menu-link.active, .navbar .menu-link:hover:not(.orange-color) {
  border: none;
  outline: none;
  color: var(--color-indigo);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .navbar .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
  }
  .navbar .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

main {
  margin-top: 4rem;
}

:root {
  --white-HC-color: #ffffff;
  --orange-HC-color: #ef7a68;
  --green-HC-color: #057683;
  --egyptian-HC-Color: #f3a94c;
  --cheng-HC-color: #e39b2f;
  --light-HC-grey: #f7f7fa;
  --text-color: #85725A;
}

@keyframes puls {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    opacity: 1;
    transform: scale(0.9);
  }
}
.padding-space {
  padding: 3rem 1rem;
}

.banner .swiper {
  height: 76%;
}

.banner-img {
  background-size: cover;
  background-position: center;
  text-align: start;
}

.banner .swiper-button-prev {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  left: -1px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-prev::after {
  font-size: 16px;
  font-weight: bold;
}
.banner .swiper-button-next {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  right: -1px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-next::after {
  font-size: 16px;
  font-weight: bold;
}

.background-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-top-settings {
  padding: 2rem 1rem;
}
.banner-top-settings .subtitle {
  font-size: 18px;
}

.banner-unidades {
  height: 315px;
  display: flex;
  align-items: center;
}

.bg-color-grey {
  background-color: #f4f4f491;
}

@media (max-width: 500px) {
  .banner .swiper-button-next {
    display: none;
  }
  .banner .swiper-button-prev {
    display: none;
  }
}
.card-color-text {
  color: #6d6d6d !important;
}

.up-card {
  transform: translateY(-50px);
  z-index: 9;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  height: 100%;
  padding: 0.5rem;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media (max-width: 526px) {
  .card-unidades .title-wrapper {
    width: 100%;
  }
  .card-unidades .title-wrapper .element-dog {
    max-width: 60px;
  }
  .card-unidades .title-wrapper .title {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .up-card {
    transform: translateY(0);
  }
}
.btn-banner {
  background-color: #057683;
  color: #fff;
  padding: 0.5rem 1rem;
}
.btn-banner:hover {
  color: #ef7a68;
  background-color: #fff;
  border: solid 1px #ef7a68;
  transition: all 0.35s ease;
}

.btn-orange {
  background: #85725a;
  color: #fff;
  padding: 12px 48px;
}
.btn-orange:hover {
  background-color: #fff;
  color: #4a4444;
  border: solid 1px #85725a;
  transition: all 0.35s ease;
}

.whatsapp-button {
  background-color: #ef7a68;
  color: white;
  border-radius: 50px;
  position: fixed;
  bottom: 21px;
  display: flex;
  padding: 10px 20px;
  right: 8px;
  text-align: center;
  color: var(--white-HC-color);
  z-index: 20;
}
.whatsapp-button .btn {
  color: white;
}
.whatsapp-button .btn:focus {
  box-shadow: none;
  color: white;
}
.whatsapp-button .dropdown-menu.show {
  transform: translateY(-82px) !important;
  width: -webkit-fill-available;
  border: none;
}
.whatsapp-button img {
  width: 32px;
  margin-right: 10px;
}
.whatsapp-button span {
  font-size: 14px;
}

.btn__redes {
  display: grid;
  bottom: 20px;
  left: 20px;
  position: fixed;
  z-index: 999;
}

.btn-i {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  color: #c71e7e;
  display: flex;
  transition: all 0.2s ease-in-out;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .btn-i {
    width: 100%;
  }
}
.btn-i iconify-icon {
  color: #df3796;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-i span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-i:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-i:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-i:hover iconify-icon {
    transform: translateX(39px);
  }
}
.btn-i:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-i:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.btn-f {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 30px;
  color: #286ed6;
  display: flex;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 768px) {
  .btn-f {
    width: 100%;
  }
}
.btn-f iconify-icon {
  color: #286ed6;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-f span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-f:hover {
  transform: scale(1.1);
  background-color: #286ed6;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-f:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-f:hover iconify-icon {
    transform: translateX(40px);
  }
}
.btn-f:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-f:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .btn-banner {
    width: 100% !important;
  }
}
.bg-azul {
  background-image: url("/img/quem-somos/img-quem-somos.jpg");
}

.bg-cinza {
  background-color: #f7f7fa;
}

.bg-cinza-2 {
  background-color: #f5f6f6;
}

.bg-verde {
  background-color: #057683;
}

.bg-servicos {
  background-image: url("/img/laranja.png");
}

.bg-cat {
  background-image: url("/img/home/bg-cat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 100%;
  transform: translateY(80px);
  border-radius: 10px;
}

.bg-cardiac {
  background: #ef7a68;
  height: 50%;
}

.bg-footer {
  background-image: url("/img/footer/redes-sociais.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .bg-footer {
    background-image: none;
    background-color: #057683;
  }
}
.swiper {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1rem;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .swiper {
    width: 100%;
  }
}
.wrapper {
  height: 100%;
}

.block__item {
  margin-bottom: 20px;
}

.block__title {
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.block__title::before,
.block__title::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 8px;
  transition: all 0.3s ease 0s;
}

.block__title:before {
  transform: rotate(40deg);
  left: 0;
}

.block__title::after {
  transform: rotate(-40deg);
  left: 8px;
}

.block__title.active::before,
.block__title.active::after {
  background-color: #ef7a68;
}

.block__title.active::before {
  transform: rotate(-40deg);
}

.block__title.active::after {
  transform: rotate(40deg);
}

.block__text {
  display: none;
  padding-top: 10px;
}

.faq-conteudo .accordion-flush .accordion-item .accordion-button {
  border-radius: 10px;
}
.faq-conteudo .accordion-button {
  position: relative;
  display: grid;
  width: 100%;
  padding: 2rem 2.25rem;
  font-size: 1rem;
  color: #4A4444;
  font-family: "Poppins";
  text-align: left;
  background-color: #F5F6F6;
  border: 0;
  border-radius: 10px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  flex-direction: row-reverse;
  align-items: center;
  align-content: stretch;
}
.faq-conteudo .accordion-button::after {
  flex-shrink: 0;
  position: absolute;
  width: 1.25rem;
  left: 12px;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.color-footer {
  background-color: #0B3C41;
  padding-top: 3.5rem;
  font-size: 0.8rem;
  text-align: start;
}
@media (max-width: 767.98px) {
  .color-footer .brand-logo {
    width: 300px;
  }
}
.color-footer a {
  color: white;
  text-decoration: none;
  line-height: 25px;
}
.color-footer a:hover {
  color: #EF7A68;
}
.color-footer p {
  line-height: 25px;
  word-break: break-word;
}

.bg-footer {
  transform: translateY(40px);
}

.img-login {
  background-image: url("/img/pets-banner.png");
  object-fit: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .img-login {
    background-image: none;
  }
}
/*Configurações Gerais*/
.orange-color {
  color: #EF7A68 !important;
}

.verde-color {
  color: #057683;
}

.marrom-color {
  color: #4A4444;
}

.marrom-secondary {
  color: #85725A;
}

:root {
  --color-white: #fff;
  --color-light: hsl(206, 33%, 96%);
  --color-black: #000;
  --color-night: hsl(214, 100%, 10%);
  --color-purple: hsl(291, 64%, 42%);
  --color-indigo: #057683;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  	0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  	0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.brand-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}
.brand-link span {
  color: #98F3AC;
}

.navbar {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  position: fixed;
  width: 100%;
}
.navbar .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.navbar .burger {
  position: relative;
  display: none;
  cursor: pointer;
  width: 2rem;
  height: 1rem;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}
.navbar .burger-line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 0.25rem;
  background: var(--color-black);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.navbar .burger-line:nth-child(1) {
  top: 0;
}
.navbar .burger-line:nth-child(2), .navbar .burger-line:nth-child(3) {
  top: 0.5rem;
}
.navbar .burger-line:nth-child(4) {
  top: 1rem;
}
.navbar .burger.active .burger-line:nth-child(1), .navbar .burger.active .burger-line:nth-child(4) {
  top: 1.25rem;
  width: 0%;
  left: 50%;
}
.navbar .burger.active .burger-line:nth-child(2) {
  transform: rotate(45deg);
}
.navbar .burger.active .burger-line:nth-child(3) {
  transform: rotate(-45deg);
}
@media (max-width: 1199.98px) {
  .navbar .menu {
    width: 100%;
  }
}
.navbar .menu-inner {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar .menu .menu-item {
  display: flex;
  align-items: center;
}
.navbar .menu-link {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  border: none;
  color: #AEAEAE;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease-in-out;
  padding: 10px;
  text-align: center;
}
.navbar .menu-link.active, .navbar .menu-link:hover:not(.orange-color) {
  border: none;
  outline: none;
  color: var(--color-indigo);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .navbar .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
  }
  .navbar .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

main {
  margin-top: 4rem;
}

:root {
  --white-HC-color: #ffffff;
  --orange-HC-color: #ef7a68;
  --green-HC-color: #057683;
  --egyptian-HC-Color: #f3a94c;
  --cheng-HC-color: #e39b2f;
  --light-HC-grey: #f7f7fa;
  --text-color: #85725A;
}

@keyframes puls {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    opacity: 1;
    transform: scale(0.9);
  }
}
.padding-space {
  padding: 3rem 1rem;
}

.banner .swiper {
  height: 76%;
}

.banner-img {
  background-size: cover;
  background-position: center;
  text-align: start;
}

.banner .swiper-button-prev {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  left: -1px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-prev::after {
  font-size: 16px;
  font-weight: bold;
}
.banner .swiper-button-next {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  right: -1px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-next::after {
  font-size: 16px;
  font-weight: bold;
}

.background-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-top-settings {
  padding: 2rem 1rem;
}
.banner-top-settings .subtitle {
  font-size: 18px;
}

.banner-unidades {
  height: 315px;
  display: flex;
  align-items: center;
}

.bg-color-grey {
  background-color: #f4f4f491;
}

@media (max-width: 500px) {
  .banner .swiper-button-next {
    display: none;
  }
  .banner .swiper-button-prev {
    display: none;
  }
}
.card-color-text {
  color: #6d6d6d !important;
}

.up-card {
  transform: translateY(-50px);
  z-index: 9;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  height: 100%;
  padding: 0.5rem;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media (max-width: 526px) {
  .card-unidades .title-wrapper {
    width: 100%;
  }
  .card-unidades .title-wrapper .element-dog {
    max-width: 60px;
  }
  .card-unidades .title-wrapper .title {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .up-card {
    transform: translateY(0);
  }
}
.btn-banner {
  background-color: #057683;
  color: #fff;
  padding: 0.5rem 1rem;
}
.btn-banner:hover {
  color: #ef7a68;
  background-color: #fff;
  border: solid 1px #ef7a68;
  transition: all 0.35s ease;
}

.btn-orange {
  background: #85725a;
  color: #fff;
  padding: 12px 48px;
}
.btn-orange:hover {
  background-color: #fff;
  color: #4a4444;
  border: solid 1px #85725a;
  transition: all 0.35s ease;
}

.whatsapp-button {
  background-color: #ef7a68;
  color: white;
  border-radius: 50px;
  position: fixed;
  bottom: 21px;
  display: flex;
  padding: 10px 20px;
  right: 8px;
  text-align: center;
  color: var(--white-HC-color);
  z-index: 20;
}
.whatsapp-button .btn {
  color: white;
}
.whatsapp-button .btn:focus {
  box-shadow: none;
  color: white;
}
.whatsapp-button .dropdown-menu.show {
  transform: translateY(-82px) !important;
  width: -webkit-fill-available;
  border: none;
}
.whatsapp-button img {
  width: 32px;
  margin-right: 10px;
}
.whatsapp-button span {
  font-size: 14px;
}

.btn__redes {
  display: grid;
  bottom: 20px;
  left: 20px;
  position: fixed;
  z-index: 999;
}

.btn-i {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  color: #c71e7e;
  display: flex;
  transition: all 0.2s ease-in-out;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .btn-i {
    width: 100%;
  }
}
.btn-i iconify-icon {
  color: #df3796;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-i span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-i:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-i:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-i:hover iconify-icon {
    transform: translateX(39px);
  }
}
.btn-i:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-i:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.btn-f {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 30px;
  color: #286ed6;
  display: flex;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 768px) {
  .btn-f {
    width: 100%;
  }
}
.btn-f iconify-icon {
  color: #286ed6;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-f span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-f:hover {
  transform: scale(1.1);
  background-color: #286ed6;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-f:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-f:hover iconify-icon {
    transform: translateX(40px);
  }
}
.btn-f:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-f:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .btn-banner {
    width: 100% !important;
  }
}
.bg-azul {
  background-image: url("/img/quem-somos/img-quem-somos.jpg");
}

.bg-cinza {
  background-color: #f7f7fa;
}

.bg-cinza-2 {
  background-color: #f5f6f6;
}

.bg-verde {
  background-color: #057683;
}

.bg-servicos {
  background-image: url("/img/laranja.png");
}

.bg-cat {
  background-image: url("/img/home/bg-cat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 100%;
  transform: translateY(80px);
  border-radius: 10px;
}

.bg-cardiac {
  background: #ef7a68;
  height: 50%;
}

.bg-footer {
  background-image: url("/img/footer/redes-sociais.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .bg-footer {
    background-image: none;
    background-color: #057683;
  }
}
.swiper {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1rem;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .swiper {
    width: 100%;
  }
}
.wrapper {
  height: 100%;
}

.block__item {
  margin-bottom: 20px;
}

.block__title {
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.block__title::before,
.block__title::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 8px;
  transition: all 0.3s ease 0s;
}

.block__title:before {
  transform: rotate(40deg);
  left: 0;
}

.block__title::after {
  transform: rotate(-40deg);
  left: 8px;
}

.block__title.active::before,
.block__title.active::after {
  background-color: #ef7a68;
}

.block__title.active::before {
  transform: rotate(-40deg);
}

.block__title.active::after {
  transform: rotate(40deg);
}

.block__text {
  display: none;
  padding-top: 10px;
}

.faq-conteudo .accordion-flush .accordion-item .accordion-button {
  border-radius: 10px;
}
.faq-conteudo .accordion-button {
  position: relative;
  display: grid;
  width: 100%;
  padding: 2rem 2.25rem;
  font-size: 1rem;
  color: #4A4444;
  font-family: "Poppins";
  text-align: left;
  background-color: #F5F6F6;
  border: 0;
  border-radius: 10px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  flex-direction: row-reverse;
  align-items: center;
  align-content: stretch;
}
.faq-conteudo .accordion-button::after {
  flex-shrink: 0;
  position: absolute;
  width: 1.25rem;
  left: 12px;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.color-footer {
  background-color: #0B3C41;
  padding-top: 3.5rem;
  font-size: 0.8rem;
  text-align: start;
}
@media (max-width: 767.98px) {
  .color-footer .brand-logo {
    width: 300px;
  }
}
.color-footer a {
  color: white;
  text-decoration: none;
  line-height: 25px;
}
.color-footer a:hover {
  color: #EF7A68;
}
.color-footer p {
  line-height: 25px;
  word-break: break-word;
}

.bg-footer {
  transform: translateY(40px);
}

.img-login {
  background-image: url("/img/pets-banner.png");
  object-fit: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .img-login {
    background-image: none;
  }
}
/*Configurações Gerais*/
.orange-color {
  color: #EF7A68 !important;
}

.verde-color {
  color: #057683;
}

.marrom-color {
  color: #4A4444;
}

.marrom-secondary {
  color: #85725A;
}

#quem-somos .title-carousel-unidades {
  padding: 1rem;
  border: 3px solid #e39b2f;
  border-radius: 45px;
  width: fit-content;
  font-family: "Mitr", sans-serif;
  color: #057683;
}
#quem-somos .swiper {
  width: 100%;
  height: 100%;
}
#quem-somos .swiper .swiper-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #F7F7FA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#quem-somos .swiper .swiper-wrapper .swiper-slide:nth-child(even) .card-bg {
  background: transparent linear-gradient(180deg, #F8EDEB 0%, #F5C5BC 100%) 0% 0% no-repeat padding-box;
  padding: 28px 28px 0px 28px;
  border-radius: 12px;
}
#quem-somos .swiper .swiper-wrapper .swiper-slide:nth-child(odd) .card-bg {
  background: transparent linear-gradient(180deg, #EBEBEB 0%, #B9BDCF 100%) 0% 0% no-repeat padding-box;
  padding: 28px 28px 0px 28px;
  border-radius: 12px;
}
#quem-somos .swiper .swiper-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#quem-somos .swiper .swiper-wrapper .swiper-slide p {
  color: #4A4444;
  font-size: 16px;
  font-weight: normal;
}
#quem-somos .swiper .swiper-wrapper .swiper-slide p span {
  text-align: left;
  font-size: 16px;
  font-weight: lighter;
  font-family: "Poppins";
  letter-spacing: 0px;
  color: #85725A;
}
#quem-somos .swiper#infraestrutura {
  height: 300px;
}
@media (max-width: 575.98px) {
  #quem-somos .swiper#infraestrutura {
    height: 150px;
  }
}
#quem-somos .swiper#infraestrutura .swiper-button-next {
  width: 16px;
  height: 16px;
}
#quem-somos .swiper#infraestrutura .swiper-button-next:after {
  border: none;
  color: #fff;
  padding: 12px;
  font-size: 20px;
  background-color: #057683;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
#quem-somos .swiper#infraestrutura .swiper-button-prev {
  width: 16px;
  height: 16px;
}
#quem-somos .swiper#infraestrutura .swiper-button-prev:after {
  border: none;
  padding: 12px;
  color: #fff;
  font-size: 20px;
  background-color: #057683;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
#quem-somos .swiper#profissional .swiper-button-next {
  width: 16px;
  height: 16px;
}
#quem-somos .swiper#profissional .swiper-button-next:after {
  border: none;
  color: #fff;
  padding: 12px;
  font-size: 20px;
  background-color: #057683;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
#quem-somos .swiper#profissional .swiper-button-prev {
  width: 16px;
  height: 16px;
}
#quem-somos .swiper#profissional .swiper-button-prev:after {
  border: none;
  padding: 12px;
  color: #fff;
  font-size: 20px;
  background-color: #057683;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

:root {
  --color-white: #fff;
  --color-light: hsl(206, 33%, 96%);
  --color-black: #000;
  --color-night: hsl(214, 100%, 10%);
  --color-purple: hsl(291, 64%, 42%);
  --color-indigo: #057683;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  	0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  	0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.brand-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}
.brand-link span {
  color: #98F3AC;
}

.navbar {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  position: fixed;
  width: 100%;
}
.navbar .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.navbar .burger {
  position: relative;
  display: none;
  cursor: pointer;
  width: 2rem;
  height: 1rem;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}
.navbar .burger-line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 0.25rem;
  background: var(--color-black);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.navbar .burger-line:nth-child(1) {
  top: 0;
}
.navbar .burger-line:nth-child(2), .navbar .burger-line:nth-child(3) {
  top: 0.5rem;
}
.navbar .burger-line:nth-child(4) {
  top: 1rem;
}
.navbar .burger.active .burger-line:nth-child(1), .navbar .burger.active .burger-line:nth-child(4) {
  top: 1.25rem;
  width: 0%;
  left: 50%;
}
.navbar .burger.active .burger-line:nth-child(2) {
  transform: rotate(45deg);
}
.navbar .burger.active .burger-line:nth-child(3) {
  transform: rotate(-45deg);
}
@media (max-width: 1199.98px) {
  .navbar .menu {
    width: 100%;
  }
}
.navbar .menu-inner {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar .menu .menu-item {
  display: flex;
  align-items: center;
}
.navbar .menu-link {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  border: none;
  color: #AEAEAE;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease-in-out;
  padding: 10px;
  text-align: center;
}
.navbar .menu-link.active, .navbar .menu-link:hover:not(.orange-color) {
  border: none;
  outline: none;
  color: var(--color-indigo);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .navbar .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
  }
  .navbar .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

main {
  margin-top: 4rem;
}

:root {
  --white-HC-color: #ffffff;
  --orange-HC-color: #ef7a68;
  --green-HC-color: #057683;
  --egyptian-HC-Color: #f3a94c;
  --cheng-HC-color: #e39b2f;
  --light-HC-grey: #f7f7fa;
  --text-color: #85725A;
}

@keyframes puls {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    opacity: 1;
    transform: scale(0.9);
  }
}
.padding-space {
  padding: 3rem 1rem;
}

.banner .swiper {
  height: 76%;
}

.banner-img {
  background-size: cover;
  background-position: center;
  text-align: start;
}

.banner .swiper-button-prev {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  left: -1px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-prev::after {
  font-size: 16px;
  font-weight: bold;
}
.banner .swiper-button-next {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  right: -1px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-next::after {
  font-size: 16px;
  font-weight: bold;
}

.background-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-top-settings {
  padding: 2rem 1rem;
}
.banner-top-settings .subtitle {
  font-size: 18px;
}

.banner-unidades {
  height: 315px;
  display: flex;
  align-items: center;
}

.bg-color-grey {
  background-color: #f4f4f491;
}

@media (max-width: 500px) {
  .banner .swiper-button-next {
    display: none;
  }
  .banner .swiper-button-prev {
    display: none;
  }
}
.card-color-text {
  color: #6d6d6d !important;
}

.up-card {
  transform: translateY(-50px);
  z-index: 9;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  height: 100%;
  padding: 0.5rem;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media (max-width: 526px) {
  .card-unidades .title-wrapper {
    width: 100%;
  }
  .card-unidades .title-wrapper .element-dog {
    max-width: 60px;
  }
  .card-unidades .title-wrapper .title {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .up-card {
    transform: translateY(0);
  }
}
.btn-banner {
  background-color: #057683;
  color: #fff;
  padding: 0.5rem 1rem;
}
.btn-banner:hover {
  color: #ef7a68;
  background-color: #fff;
  border: solid 1px #ef7a68;
  transition: all 0.35s ease;
}

.btn-orange {
  background: #85725a;
  color: #fff;
  padding: 12px 48px;
}
.btn-orange:hover {
  background-color: #fff;
  color: #4a4444;
  border: solid 1px #85725a;
  transition: all 0.35s ease;
}

.whatsapp-button {
  background-color: #ef7a68;
  color: white;
  border-radius: 50px;
  position: fixed;
  bottom: 21px;
  display: flex;
  padding: 10px 20px;
  right: 8px;
  text-align: center;
  color: var(--white-HC-color);
  z-index: 20;
}
.whatsapp-button .btn {
  color: white;
}
.whatsapp-button .btn:focus {
  box-shadow: none;
  color: white;
}
.whatsapp-button .dropdown-menu.show {
  transform: translateY(-82px) !important;
  width: -webkit-fill-available;
  border: none;
}
.whatsapp-button img {
  width: 32px;
  margin-right: 10px;
}
.whatsapp-button span {
  font-size: 14px;
}

.btn__redes {
  display: grid;
  bottom: 20px;
  left: 20px;
  position: fixed;
  z-index: 999;
}

.btn-i {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  color: #c71e7e;
  display: flex;
  transition: all 0.2s ease-in-out;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .btn-i {
    width: 100%;
  }
}
.btn-i iconify-icon {
  color: #df3796;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-i span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-i:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-i:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-i:hover iconify-icon {
    transform: translateX(39px);
  }
}
.btn-i:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-i:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.btn-f {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 30px;
  color: #286ed6;
  display: flex;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 768px) {
  .btn-f {
    width: 100%;
  }
}
.btn-f iconify-icon {
  color: #286ed6;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-f span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-f:hover {
  transform: scale(1.1);
  background-color: #286ed6;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-f:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-f:hover iconify-icon {
    transform: translateX(40px);
  }
}
.btn-f:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-f:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .btn-banner {
    width: 100% !important;
  }
}
.bg-azul {
  background-image: url("/img/quem-somos/img-quem-somos.jpg");
}

.bg-cinza {
  background-color: #f7f7fa;
}

.bg-cinza-2 {
  background-color: #f5f6f6;
}

.bg-verde {
  background-color: #057683;
}

.bg-servicos {
  background-image: url("/img/laranja.png");
}

.bg-cat {
  background-image: url("/img/home/bg-cat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 100%;
  transform: translateY(80px);
  border-radius: 10px;
}

.bg-cardiac {
  background: #ef7a68;
  height: 50%;
}

.bg-footer {
  background-image: url("/img/footer/redes-sociais.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .bg-footer {
    background-image: none;
    background-color: #057683;
  }
}
.swiper {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1rem;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .swiper {
    width: 100%;
  }
}
.wrapper {
  height: 100%;
}

.block__item {
  margin-bottom: 20px;
}

.block__title {
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.block__title::before,
.block__title::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 8px;
  transition: all 0.3s ease 0s;
}

.block__title:before {
  transform: rotate(40deg);
  left: 0;
}

.block__title::after {
  transform: rotate(-40deg);
  left: 8px;
}

.block__title.active::before,
.block__title.active::after {
  background-color: #ef7a68;
}

.block__title.active::before {
  transform: rotate(-40deg);
}

.block__title.active::after {
  transform: rotate(40deg);
}

.block__text {
  display: none;
  padding-top: 10px;
}

.faq-conteudo .accordion-flush .accordion-item .accordion-button {
  border-radius: 10px;
}
.faq-conteudo .accordion-button {
  position: relative;
  display: grid;
  width: 100%;
  padding: 2rem 2.25rem;
  font-size: 1rem;
  color: #4A4444;
  font-family: "Poppins";
  text-align: left;
  background-color: #F5F6F6;
  border: 0;
  border-radius: 10px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  flex-direction: row-reverse;
  align-items: center;
  align-content: stretch;
}
.faq-conteudo .accordion-button::after {
  flex-shrink: 0;
  position: absolute;
  width: 1.25rem;
  left: 12px;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.color-footer {
  background-color: #0B3C41;
  padding-top: 3.5rem;
  font-size: 0.8rem;
  text-align: start;
}
@media (max-width: 767.98px) {
  .color-footer .brand-logo {
    width: 300px;
  }
}
.color-footer a {
  color: white;
  text-decoration: none;
  line-height: 25px;
}
.color-footer a:hover {
  color: #EF7A68;
}
.color-footer p {
  line-height: 25px;
  word-break: break-word;
}

.bg-footer {
  transform: translateY(40px);
}

.img-login {
  background-image: url("/img/pets-banner.png");
  object-fit: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .img-login {
    background-image: none;
  }
}
/*Configurações Gerais*/
.orange-color {
  color: #EF7A68 !important;
}

.verde-color {
  color: #057683;
}

.marrom-color {
  color: #4A4444;
}

.marrom-secondary {
  color: #85725A;
}

#servicos {
  overflow: hidden;
}
#servicos .banner-top-settings {
  background-image: url("/img/servicos/banner.jpg");
}
#servicos .banner-top-settings h1 {
  color: #057683;
}
#servicos .banner-top-settings p {
  color: #9A9A9A;
}
#servicos .card-servicos {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  margin-bottom: 2rem;
  width: 90%;
  transition: all 0.3s ease;
}
#servicos .card-servicos:hover .element-pata {
  transform: translateY(-5px);
}
#servicos .card-servicos:hover .description-wrapper .subtitle {
  color: var(--orange-HC-color);
}
#servicos .card-servicos .description-wrapper {
  top: 100%;
  background-color: var(--white-HC-color);
  padding: 28px;
  border-radius: 30px;
  width: 80%;
  border: 1px solid #057683;
  text-align: center;
}
#servicos .card-servicos .description-wrapper .dropdown-toggle {
  background-color: #EF7A68;
  color: white;
}
#servicos .card-servicos .description-wrapper .element-pata {
  position: absolute;
  transition: all 0.3s ease;
  left: -31px;
  top: -5px;
}
#servicos .card-servicos .description-wrapper .title {
  color: #057683;
  font-size: 20px;
  font-family: "Mitr", sans-serif;
}
#servicos .card-servicos .description-wrapper .subtitle {
  color: var(--egyptian-HC-Color);
  font-size: 16px;
  margin-bottom: 0;
  display: inherit;
  font-family: "Mitr", sans-serif;
  font-weight: 400;
}
#servicos .card-servicos .fab {
  color: var(--orange-HC-color);
}

:root {
  --color-white: #fff;
  --color-light: hsl(206, 33%, 96%);
  --color-black: #000;
  --color-night: hsl(214, 100%, 10%);
  --color-purple: hsl(291, 64%, 42%);
  --color-indigo: #057683;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  	0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  	0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.brand-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}
.brand-link span {
  color: #98F3AC;
}

.navbar {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  position: fixed;
  width: 100%;
}
.navbar .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.navbar .burger {
  position: relative;
  display: none;
  cursor: pointer;
  width: 2rem;
  height: 1rem;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}
.navbar .burger-line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 0.25rem;
  background: var(--color-black);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.navbar .burger-line:nth-child(1) {
  top: 0;
}
.navbar .burger-line:nth-child(2), .navbar .burger-line:nth-child(3) {
  top: 0.5rem;
}
.navbar .burger-line:nth-child(4) {
  top: 1rem;
}
.navbar .burger.active .burger-line:nth-child(1), .navbar .burger.active .burger-line:nth-child(4) {
  top: 1.25rem;
  width: 0%;
  left: 50%;
}
.navbar .burger.active .burger-line:nth-child(2) {
  transform: rotate(45deg);
}
.navbar .burger.active .burger-line:nth-child(3) {
  transform: rotate(-45deg);
}
@media (max-width: 1199.98px) {
  .navbar .menu {
    width: 100%;
  }
}
.navbar .menu-inner {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar .menu .menu-item {
  display: flex;
  align-items: center;
}
.navbar .menu-link {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  border: none;
  color: #AEAEAE;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease-in-out;
  padding: 10px;
  text-align: center;
}
.navbar .menu-link.active, .navbar .menu-link:hover:not(.orange-color) {
  border: none;
  outline: none;
  color: var(--color-indigo);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .navbar .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
  }
  .navbar .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

main {
  margin-top: 4rem;
}

:root {
  --white-HC-color: #ffffff;
  --orange-HC-color: #ef7a68;
  --green-HC-color: #057683;
  --egyptian-HC-Color: #f3a94c;
  --cheng-HC-color: #e39b2f;
  --light-HC-grey: #f7f7fa;
  --text-color: #85725A;
}

@keyframes puls {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    opacity: 1;
    transform: scale(0.9);
  }
}
.padding-space {
  padding: 3rem 1rem;
}

.banner .swiper {
  height: 76%;
}

.banner-img {
  background-size: cover;
  background-position: center;
  text-align: start;
}

.banner .swiper-button-prev {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  left: -1px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-prev::after {
  font-size: 16px;
  font-weight: bold;
}
.banner .swiper-button-next {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  right: -1px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-next::after {
  font-size: 16px;
  font-weight: bold;
}

.background-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-top-settings {
  padding: 2rem 1rem;
}
.banner-top-settings .subtitle {
  font-size: 18px;
}

.banner-unidades {
  height: 315px;
  display: flex;
  align-items: center;
}

.bg-color-grey {
  background-color: #f4f4f491;
}

@media (max-width: 500px) {
  .banner .swiper-button-next {
    display: none;
  }
  .banner .swiper-button-prev {
    display: none;
  }
}
.card-color-text {
  color: #6d6d6d !important;
}

.up-card {
  transform: translateY(-50px);
  z-index: 9;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  height: 100%;
  padding: 0.5rem;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media (max-width: 526px) {
  .card-unidades .title-wrapper {
    width: 100%;
  }
  .card-unidades .title-wrapper .element-dog {
    max-width: 60px;
  }
  .card-unidades .title-wrapper .title {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .up-card {
    transform: translateY(0);
  }
}
.btn-banner {
  background-color: #057683;
  color: #fff;
  padding: 0.5rem 1rem;
}
.btn-banner:hover {
  color: #ef7a68;
  background-color: #fff;
  border: solid 1px #ef7a68;
  transition: all 0.35s ease;
}

.btn-orange {
  background: #85725a;
  color: #fff;
  padding: 12px 48px;
}
.btn-orange:hover {
  background-color: #fff;
  color: #4a4444;
  border: solid 1px #85725a;
  transition: all 0.35s ease;
}

.whatsapp-button {
  background-color: #ef7a68;
  color: white;
  border-radius: 50px;
  position: fixed;
  bottom: 21px;
  display: flex;
  padding: 10px 20px;
  right: 8px;
  text-align: center;
  color: var(--white-HC-color);
  z-index: 20;
}
.whatsapp-button .btn {
  color: white;
}
.whatsapp-button .btn:focus {
  box-shadow: none;
  color: white;
}
.whatsapp-button .dropdown-menu.show {
  transform: translateY(-82px) !important;
  width: -webkit-fill-available;
  border: none;
}
.whatsapp-button img {
  width: 32px;
  margin-right: 10px;
}
.whatsapp-button span {
  font-size: 14px;
}

.btn__redes {
  display: grid;
  bottom: 20px;
  left: 20px;
  position: fixed;
  z-index: 999;
}

.btn-i {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  color: #c71e7e;
  display: flex;
  transition: all 0.2s ease-in-out;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .btn-i {
    width: 100%;
  }
}
.btn-i iconify-icon {
  color: #df3796;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-i span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-i:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-i:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-i:hover iconify-icon {
    transform: translateX(39px);
  }
}
.btn-i:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-i:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.btn-f {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 30px;
  color: #286ed6;
  display: flex;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 768px) {
  .btn-f {
    width: 100%;
  }
}
.btn-f iconify-icon {
  color: #286ed6;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-f span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-f:hover {
  transform: scale(1.1);
  background-color: #286ed6;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-f:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-f:hover iconify-icon {
    transform: translateX(40px);
  }
}
.btn-f:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-f:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .btn-banner {
    width: 100% !important;
  }
}
.bg-azul {
  background-image: url("/img/quem-somos/img-quem-somos.jpg");
}

.bg-cinza {
  background-color: #f7f7fa;
}

.bg-cinza-2 {
  background-color: #f5f6f6;
}

.bg-verde {
  background-color: #057683;
}

.bg-servicos {
  background-image: url("/img/laranja.png");
}

.bg-cat {
  background-image: url("/img/home/bg-cat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 100%;
  transform: translateY(80px);
  border-radius: 10px;
}

.bg-cardiac {
  background: #ef7a68;
  height: 50%;
}

.bg-footer {
  background-image: url("/img/footer/redes-sociais.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .bg-footer {
    background-image: none;
    background-color: #057683;
  }
}
.swiper {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1rem;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .swiper {
    width: 100%;
  }
}
.wrapper {
  height: 100%;
}

.block__item {
  margin-bottom: 20px;
}

.block__title {
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.block__title::before,
.block__title::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 8px;
  transition: all 0.3s ease 0s;
}

.block__title:before {
  transform: rotate(40deg);
  left: 0;
}

.block__title::after {
  transform: rotate(-40deg);
  left: 8px;
}

.block__title.active::before,
.block__title.active::after {
  background-color: #ef7a68;
}

.block__title.active::before {
  transform: rotate(-40deg);
}

.block__title.active::after {
  transform: rotate(40deg);
}

.block__text {
  display: none;
  padding-top: 10px;
}

.faq-conteudo .accordion-flush .accordion-item .accordion-button {
  border-radius: 10px;
}
.faq-conteudo .accordion-button {
  position: relative;
  display: grid;
  width: 100%;
  padding: 2rem 2.25rem;
  font-size: 1rem;
  color: #4A4444;
  font-family: "Poppins";
  text-align: left;
  background-color: #F5F6F6;
  border: 0;
  border-radius: 10px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  flex-direction: row-reverse;
  align-items: center;
  align-content: stretch;
}
.faq-conteudo .accordion-button::after {
  flex-shrink: 0;
  position: absolute;
  width: 1.25rem;
  left: 12px;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.color-footer {
  background-color: #0B3C41;
  padding-top: 3.5rem;
  font-size: 0.8rem;
  text-align: start;
}
@media (max-width: 767.98px) {
  .color-footer .brand-logo {
    width: 300px;
  }
}
.color-footer a {
  color: white;
  text-decoration: none;
  line-height: 25px;
}
.color-footer a:hover {
  color: #EF7A68;
}
.color-footer p {
  line-height: 25px;
  word-break: break-word;
}

.bg-footer {
  transform: translateY(40px);
}

.img-login {
  background-image: url("/img/pets-banner.png");
  object-fit: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .img-login {
    background-image: none;
  }
}
/*Configurações Gerais*/
.orange-color {
  color: #EF7A68 !important;
}

.verde-color {
  color: #057683;
}

.marrom-color {
  color: #4A4444;
}

.marrom-secondary {
  color: #85725A;
}

#especialidades .banner-top-settings {
  background-image: url("/img/servicos/banner.jpg");
}
#especialidades .banner-top-settings h1 {
  color: #057683;
}
#especialidades .banner-top-settings p {
  color: #9A9A9A;
}
#especialidades .card-especialidades {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #85725A;
  padding: 1rem;
  position: relative;
  text-align: center;
  border-radius: 30px;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#especialidades .card-especialidades:hover .element-pata {
  transform: translateY(-5px);
}
#especialidades .card-especialidades:hover .fab {
  color: var(--white-HC-color);
}
#especialidades .card-especialidades .element-pata {
  position: absolute;
  transition: all 0.3s ease;
  left: -31px;
  top: -5px;
}
#especialidades .card-especialidades .title {
  color: #85725A;
  font-size: 20px;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  width: min-content;
}
#especialidades .card-especialidades .subtitle {
  color: #F3A94C;
  text-shadow: 0px 3px 6px #BC131329;
  font-size: 16px;
  margin-bottom: 0;
  display: inherit;
  font-family: "Mitr", sans-serif;
  font-weight: 400;
}
#especialidades .card-especialidades .fab {
  color: var(--orange-HC-color);
}

:root {
  --color-white: #fff;
  --color-light: hsl(206, 33%, 96%);
  --color-black: #000;
  --color-night: hsl(214, 100%, 10%);
  --color-purple: hsl(291, 64%, 42%);
  --color-indigo: #057683;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  	0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  	0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.brand-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}
.brand-link span {
  color: #98F3AC;
}

.navbar {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  position: fixed;
  width: 100%;
}
.navbar .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.navbar .burger {
  position: relative;
  display: none;
  cursor: pointer;
  width: 2rem;
  height: 1rem;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}
.navbar .burger-line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 0.25rem;
  background: var(--color-black);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.navbar .burger-line:nth-child(1) {
  top: 0;
}
.navbar .burger-line:nth-child(2), .navbar .burger-line:nth-child(3) {
  top: 0.5rem;
}
.navbar .burger-line:nth-child(4) {
  top: 1rem;
}
.navbar .burger.active .burger-line:nth-child(1), .navbar .burger.active .burger-line:nth-child(4) {
  top: 1.25rem;
  width: 0%;
  left: 50%;
}
.navbar .burger.active .burger-line:nth-child(2) {
  transform: rotate(45deg);
}
.navbar .burger.active .burger-line:nth-child(3) {
  transform: rotate(-45deg);
}
@media (max-width: 1199.98px) {
  .navbar .menu {
    width: 100%;
  }
}
.navbar .menu-inner {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar .menu .menu-item {
  display: flex;
  align-items: center;
}
.navbar .menu-link {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  border: none;
  color: #AEAEAE;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease-in-out;
  padding: 10px;
  text-align: center;
}
.navbar .menu-link.active, .navbar .menu-link:hover:not(.orange-color) {
  border: none;
  outline: none;
  color: var(--color-indigo);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .navbar .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
  }
  .navbar .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

main {
  margin-top: 4rem;
}

:root {
  --white-HC-color: #ffffff;
  --orange-HC-color: #ef7a68;
  --green-HC-color: #057683;
  --egyptian-HC-Color: #f3a94c;
  --cheng-HC-color: #e39b2f;
  --light-HC-grey: #f7f7fa;
  --text-color: #85725A;
}

@keyframes puls {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    opacity: 1;
    transform: scale(0.9);
  }
}
.padding-space {
  padding: 3rem 1rem;
}

.banner .swiper {
  height: 76%;
}

.banner-img {
  background-size: cover;
  background-position: center;
  text-align: start;
}

.banner .swiper-button-prev {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  left: -1px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-prev::after {
  font-size: 16px;
  font-weight: bold;
}
.banner .swiper-button-next {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  right: -1px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-next::after {
  font-size: 16px;
  font-weight: bold;
}

.background-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-top-settings {
  padding: 2rem 1rem;
}
.banner-top-settings .subtitle {
  font-size: 18px;
}

.banner-unidades {
  height: 315px;
  display: flex;
  align-items: center;
}

.bg-color-grey {
  background-color: #f4f4f491;
}

@media (max-width: 500px) {
  .banner .swiper-button-next {
    display: none;
  }
  .banner .swiper-button-prev {
    display: none;
  }
}
.card-color-text {
  color: #6d6d6d !important;
}

.up-card {
  transform: translateY(-50px);
  z-index: 9;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  height: 100%;
  padding: 0.5rem;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media (max-width: 526px) {
  .card-unidades .title-wrapper {
    width: 100%;
  }
  .card-unidades .title-wrapper .element-dog {
    max-width: 60px;
  }
  .card-unidades .title-wrapper .title {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .up-card {
    transform: translateY(0);
  }
}
.btn-banner {
  background-color: #057683;
  color: #fff;
  padding: 0.5rem 1rem;
}
.btn-banner:hover {
  color: #ef7a68;
  background-color: #fff;
  border: solid 1px #ef7a68;
  transition: all 0.35s ease;
}

.btn-orange {
  background: #85725a;
  color: #fff;
  padding: 12px 48px;
}
.btn-orange:hover {
  background-color: #fff;
  color: #4a4444;
  border: solid 1px #85725a;
  transition: all 0.35s ease;
}

.whatsapp-button {
  background-color: #ef7a68;
  color: white;
  border-radius: 50px;
  position: fixed;
  bottom: 21px;
  display: flex;
  padding: 10px 20px;
  right: 8px;
  text-align: center;
  color: var(--white-HC-color);
  z-index: 20;
}
.whatsapp-button .btn {
  color: white;
}
.whatsapp-button .btn:focus {
  box-shadow: none;
  color: white;
}
.whatsapp-button .dropdown-menu.show {
  transform: translateY(-82px) !important;
  width: -webkit-fill-available;
  border: none;
}
.whatsapp-button img {
  width: 32px;
  margin-right: 10px;
}
.whatsapp-button span {
  font-size: 14px;
}

.btn__redes {
  display: grid;
  bottom: 20px;
  left: 20px;
  position: fixed;
  z-index: 999;
}

.btn-i {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  color: #c71e7e;
  display: flex;
  transition: all 0.2s ease-in-out;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .btn-i {
    width: 100%;
  }
}
.btn-i iconify-icon {
  color: #df3796;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-i span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-i:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-i:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-i:hover iconify-icon {
    transform: translateX(39px);
  }
}
.btn-i:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-i:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.btn-f {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 30px;
  color: #286ed6;
  display: flex;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 768px) {
  .btn-f {
    width: 100%;
  }
}
.btn-f iconify-icon {
  color: #286ed6;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-f span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-f:hover {
  transform: scale(1.1);
  background-color: #286ed6;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-f:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-f:hover iconify-icon {
    transform: translateX(40px);
  }
}
.btn-f:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-f:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .btn-banner {
    width: 100% !important;
  }
}
.bg-azul {
  background-image: url("/img/quem-somos/img-quem-somos.jpg");
}

.bg-cinza {
  background-color: #f7f7fa;
}

.bg-cinza-2 {
  background-color: #f5f6f6;
}

.bg-verde {
  background-color: #057683;
}

.bg-servicos {
  background-image: url("/img/laranja.png");
}

.bg-cat {
  background-image: url("/img/home/bg-cat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 100%;
  transform: translateY(80px);
  border-radius: 10px;
}

.bg-cardiac {
  background: #ef7a68;
  height: 50%;
}

.bg-footer {
  background-image: url("/img/footer/redes-sociais.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .bg-footer {
    background-image: none;
    background-color: #057683;
  }
}
.swiper {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1rem;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .swiper {
    width: 100%;
  }
}
.wrapper {
  height: 100%;
}

.block__item {
  margin-bottom: 20px;
}

.block__title {
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.block__title::before,
.block__title::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 8px;
  transition: all 0.3s ease 0s;
}

.block__title:before {
  transform: rotate(40deg);
  left: 0;
}

.block__title::after {
  transform: rotate(-40deg);
  left: 8px;
}

.block__title.active::before,
.block__title.active::after {
  background-color: #ef7a68;
}

.block__title.active::before {
  transform: rotate(-40deg);
}

.block__title.active::after {
  transform: rotate(40deg);
}

.block__text {
  display: none;
  padding-top: 10px;
}

.faq-conteudo .accordion-flush .accordion-item .accordion-button {
  border-radius: 10px;
}
.faq-conteudo .accordion-button {
  position: relative;
  display: grid;
  width: 100%;
  padding: 2rem 2.25rem;
  font-size: 1rem;
  color: #4A4444;
  font-family: "Poppins";
  text-align: left;
  background-color: #F5F6F6;
  border: 0;
  border-radius: 10px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  flex-direction: row-reverse;
  align-items: center;
  align-content: stretch;
}
.faq-conteudo .accordion-button::after {
  flex-shrink: 0;
  position: absolute;
  width: 1.25rem;
  left: 12px;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.color-footer {
  background-color: #0B3C41;
  padding-top: 3.5rem;
  font-size: 0.8rem;
  text-align: start;
}
@media (max-width: 767.98px) {
  .color-footer .brand-logo {
    width: 300px;
  }
}
.color-footer a {
  color: white;
  text-decoration: none;
  line-height: 25px;
}
.color-footer a:hover {
  color: #EF7A68;
}
.color-footer p {
  line-height: 25px;
  word-break: break-word;
}

.bg-footer {
  transform: translateY(40px);
}

.img-login {
  background-image: url("/img/pets-banner.png");
  object-fit: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .img-login {
    background-image: none;
  }
}
/*Configurações Gerais*/
.orange-color {
  color: #EF7A68 !important;
}

.verde-color {
  color: #057683;
}

.marrom-color {
  color: #4A4444;
}

.marrom-secondary {
  color: #85725A;
}

#unidades {
  overflow: hidden;
}
#unidades .banner-top-settings {
  background-image: url("/img/unidades/banner.jpg");
}
#unidades .banner-top-settings h1 {
  color: #057683;
}
#unidades .banner-top-settings p {
  color: #9A9A9A;
}
#unidades .unidades-wrapper {
  background-repeat: no-repeat;
  background-position: 60px;
  background-color: var(--white-HC-color);
}
#unidades .unidades-wrapper .swiper {
  width: 100%;
  height: 100%;
  background-color: var(--white-HC-color);
}
#unidades .unidades-wrapper .swiper-button-prev {
  background-image: url("/img/icones/button-prev-swiper.png");
  background-repeat: no-repeat;
  height: 70px;
  width: 65px;
  transition: all 0.3s ease;
}
#unidades .unidades-wrapper .swiper-button-prev:after {
  display: none;
}
#unidades .unidades-wrapper .swiper-button-prev:hover {
  transform: translateX(-5px);
}
#unidades .unidades-wrapper .swiper-button-next {
  background-image: url("/img/icones/button-next-swiper.png");
  background-repeat: no-repeat;
  height: 70px;
  width: 55px;
  transition: all 0.3s ease;
}
#unidades .unidades-wrapper .swiper-button-next:after {
  display: none;
}
#unidades .unidades-wrapper .swiper-button-next:hover {
  transform: translateX(5px);
}
#unidades .unidades-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--white-HC-color);
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#unidades .unidades-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#unidades .unidades-wrapper .swiper {
  width: 100%;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--white-HC-color);
}
#unidades .unidades-wrapper .swiper-slide {
  background-size: cover;
  background-position: center;
}
#unidades .unidades-wrapper .mySwiperUnidades {
  height: 290px;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 20px 20px 0 0;
}
#unidades .unidades-wrapper .mySwipethumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
  background-color: var(--white-HC-color);
}
#unidades .unidades-wrapper .mySwiperthumbs .swiper-slide {
  width: 25%;
  height: 100px;
  opacity: 0.4;
}
#unidades .unidades-wrapper .mySwiperthumbs .swiper-slide-thumb-active {
  opacity: 1;
}
#unidades .unidades-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#unidades .card-unidades {
  padding: 2rem;
  background-color: var(--white-HC-color);
  border-radius: 20px;
}
#unidades .card-unidades:hover .title-wrapper {
  border: 3px solid #e39b2f;
}
#unidades .card-unidades .title-wrapper {
  border-radius: 90px;
  border: 3px solid #057683;
  padding: 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  width: 85%;
}
#unidades .card-unidades .title-wrapper .title {
  margin-bottom: 0;
  color: var(--green-HC-color);
  font-size: 26px;
  font-family: "Mitr", sans-serif;
}
@media (max-width: 575.98px) {
  #unidades .card-unidades .title-wrapper .title {
    font-size: 16px;
  }
}
#unidades .card-unidades .content-wrapper a, #unidades .card-unidades .content-wrapper p {
  color: #818181;
  word-break: break-all;
  font-size: 16px;
}
#unidades .card-unidades .content-wrapper a:hover, #unidades .card-unidades .content-wrapper p:hover {
  color: var(--cheng-HC-color);
}

:root {
  --color-white: #fff;
  --color-light: hsl(206, 33%, 96%);
  --color-black: #000;
  --color-night: hsl(214, 100%, 10%);
  --color-purple: hsl(291, 64%, 42%);
  --color-indigo: #057683;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  	0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  	0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.brand-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}
.brand-link span {
  color: #98F3AC;
}

.navbar {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  position: fixed;
  width: 100%;
}
.navbar .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.navbar .burger {
  position: relative;
  display: none;
  cursor: pointer;
  width: 2rem;
  height: 1rem;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}
.navbar .burger-line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 0.25rem;
  background: var(--color-black);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.navbar .burger-line:nth-child(1) {
  top: 0;
}
.navbar .burger-line:nth-child(2), .navbar .burger-line:nth-child(3) {
  top: 0.5rem;
}
.navbar .burger-line:nth-child(4) {
  top: 1rem;
}
.navbar .burger.active .burger-line:nth-child(1), .navbar .burger.active .burger-line:nth-child(4) {
  top: 1.25rem;
  width: 0%;
  left: 50%;
}
.navbar .burger.active .burger-line:nth-child(2) {
  transform: rotate(45deg);
}
.navbar .burger.active .burger-line:nth-child(3) {
  transform: rotate(-45deg);
}
@media (max-width: 1199.98px) {
  .navbar .menu {
    width: 100%;
  }
}
.navbar .menu-inner {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar .menu .menu-item {
  display: flex;
  align-items: center;
}
.navbar .menu-link {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  border: none;
  color: #AEAEAE;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease-in-out;
  padding: 10px;
  text-align: center;
}
.navbar .menu-link.active, .navbar .menu-link:hover:not(.orange-color) {
  border: none;
  outline: none;
  color: var(--color-indigo);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .navbar .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
  }
  .navbar .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .navbar .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

main {
  margin-top: 4rem;
}

:root {
  --white-HC-color: #ffffff;
  --orange-HC-color: #ef7a68;
  --green-HC-color: #057683;
  --egyptian-HC-Color: #f3a94c;
  --cheng-HC-color: #e39b2f;
  --light-HC-grey: #f7f7fa;
  --text-color: #85725A;
}

@keyframes puls {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    opacity: 1;
    transform: scale(0.9);
  }
}
.padding-space {
  padding: 3rem 1rem;
}

.banner .swiper {
  height: 76%;
}

.banner-img {
  background-size: cover;
  background-position: center;
  text-align: start;
}

.banner .swiper-button-prev {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  left: -1px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-prev::after {
  font-size: 16px;
  font-weight: bold;
}
.banner .swiper-button-next {
  background-color: #8A6B42;
  color: #fff;
  padding: 20px;
  right: -1px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border: 1px solid #B1B1B1;
}
.banner .swiper-button-next::after {
  font-size: 16px;
  font-weight: bold;
}

.background-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-top-settings {
  padding: 2rem 1rem;
}
.banner-top-settings .subtitle {
  font-size: 18px;
}

.banner-unidades {
  height: 315px;
  display: flex;
  align-items: center;
}

.bg-color-grey {
  background-color: #f4f4f491;
}

@media (max-width: 500px) {
  .banner .swiper-button-next {
    display: none;
  }
  .banner .swiper-button-prev {
    display: none;
  }
}
.card-color-text {
  color: #6d6d6d !important;
}

.up-card {
  transform: translateY(-50px);
  z-index: 9;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  height: 100%;
  padding: 0.5rem;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media (max-width: 526px) {
  .card-unidades .title-wrapper {
    width: 100%;
  }
  .card-unidades .title-wrapper .element-dog {
    max-width: 60px;
  }
  .card-unidades .title-wrapper .title {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .up-card {
    transform: translateY(0);
  }
}
.btn-banner {
  background-color: #057683;
  color: #fff;
  padding: 0.5rem 1rem;
}
.btn-banner:hover {
  color: #ef7a68;
  background-color: #fff;
  border: solid 1px #ef7a68;
  transition: all 0.35s ease;
}

.btn-orange {
  background: #85725a;
  color: #fff;
  padding: 12px 48px;
}
.btn-orange:hover {
  background-color: #fff;
  color: #4a4444;
  border: solid 1px #85725a;
  transition: all 0.35s ease;
}

.whatsapp-button {
  background-color: #ef7a68;
  color: white;
  border-radius: 50px;
  position: fixed;
  bottom: 21px;
  display: flex;
  padding: 10px 20px;
  right: 8px;
  text-align: center;
  color: var(--white-HC-color);
  z-index: 20;
}
.whatsapp-button .btn {
  color: white;
}
.whatsapp-button .btn:focus {
  box-shadow: none;
  color: white;
}
.whatsapp-button .dropdown-menu.show {
  transform: translateY(-82px) !important;
  width: -webkit-fill-available;
  border: none;
}
.whatsapp-button img {
  width: 32px;
  margin-right: 10px;
}
.whatsapp-button span {
  font-size: 14px;
}

.btn__redes {
  display: grid;
  bottom: 20px;
  left: 20px;
  position: fixed;
  z-index: 999;
}

.btn-i {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  color: #c71e7e;
  display: flex;
  transition: all 0.2s ease-in-out;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .btn-i {
    width: 100%;
  }
}
.btn-i iconify-icon {
  color: #df3796;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-i span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-i:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-i:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-i:hover iconify-icon {
    transform: translateX(39px);
  }
}
.btn-i:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-i:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.btn-f {
  width: fit-content;
  gap: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.087);
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 30px;
  color: #286ed6;
  display: flex;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 768px) {
  .btn-f {
    width: 100%;
  }
}
.btn-f iconify-icon {
  color: #286ed6;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.btn-f span {
  font-family: "Roboto", sans-serif;
  align-self: center;
  transform: translateX(0px);
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.btn-f:hover {
  transform: scale(1.1);
  background-color: #286ed6;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-f:hover iconify-icon {
  transform: translateX(0px);
  padding-right: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn-f:hover iconify-icon {
    transform: translateX(40px);
  }
}
.btn-f:hover span {
  transform: translateX(30px);
  opacity: 0;
}
.btn-f:active {
  transform: scale(1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .btn-banner {
    width: 100% !important;
  }
}
.bg-azul {
  background-image: url("/img/quem-somos/img-quem-somos.jpg");
}

.bg-cinza {
  background-color: #f7f7fa;
}

.bg-cinza-2 {
  background-color: #f5f6f6;
}

.bg-verde {
  background-color: #057683;
}

.bg-servicos {
  background-image: url("/img/laranja.png");
}

.bg-cat {
  background-image: url("/img/home/bg-cat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 100%;
  transform: translateY(80px);
  border-radius: 10px;
}

.bg-cardiac {
  background: #ef7a68;
  height: 50%;
}

.bg-footer {
  background-image: url("/img/footer/redes-sociais.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .bg-footer {
    background-image: none;
    background-color: #057683;
  }
}
.swiper {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1rem;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .swiper {
    width: 100%;
  }
}
.wrapper {
  height: 100%;
}

.block__item {
  margin-bottom: 20px;
}

.block__title {
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.block__title::before,
.block__title::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 8px;
  transition: all 0.3s ease 0s;
}

.block__title:before {
  transform: rotate(40deg);
  left: 0;
}

.block__title::after {
  transform: rotate(-40deg);
  left: 8px;
}

.block__title.active::before,
.block__title.active::after {
  background-color: #ef7a68;
}

.block__title.active::before {
  transform: rotate(-40deg);
}

.block__title.active::after {
  transform: rotate(40deg);
}

.block__text {
  display: none;
  padding-top: 10px;
}

.faq-conteudo .accordion-flush .accordion-item .accordion-button {
  border-radius: 10px;
}
.faq-conteudo .accordion-button {
  position: relative;
  display: grid;
  width: 100%;
  padding: 2rem 2.25rem;
  font-size: 1rem;
  color: #4A4444;
  font-family: "Poppins";
  text-align: left;
  background-color: #F5F6F6;
  border: 0;
  border-radius: 10px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  flex-direction: row-reverse;
  align-items: center;
  align-content: stretch;
}
.faq-conteudo .accordion-button::after {
  flex-shrink: 0;
  position: absolute;
  width: 1.25rem;
  left: 12px;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.color-footer {
  background-color: #0B3C41;
  padding-top: 3.5rem;
  font-size: 0.8rem;
  text-align: start;
}
@media (max-width: 767.98px) {
  .color-footer .brand-logo {
    width: 300px;
  }
}
.color-footer a {
  color: white;
  text-decoration: none;
  line-height: 25px;
}
.color-footer a:hover {
  color: #EF7A68;
}
.color-footer p {
  line-height: 25px;
  word-break: break-word;
}

.bg-footer {
  transform: translateY(40px);
}

.img-login {
  background-image: url("/img/pets-banner.png");
  object-fit: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .img-login {
    background-image: none;
  }
}
/*Configurações Gerais*/
.orange-color {
  color: #EF7A68 !important;
}

.verde-color {
  color: #057683;
}

.marrom-color {
  color: #4A4444;
}

.marrom-secondary {
  color: #85725A;
}

#contato .banner h1 {
  color: #fff;
  font-weight: bold;
  text-align: center;
}
#contato .banner p {
  text-align: center;
  color: #fff;
}
#contato h2 {
  color: #85725A;
}
#contato p {
  color: #4A4444;
}
#contato input {
  padding: 20px;
  border-radius: 8px;
  border-color: #81A2B8;
}
#contato textarea {
  border-radius: 8px;
  border-color: #81A2B8;
}

#exames {
  overflow: hidden;
}
#exames .imagem-capa {
  background-image: url("/img/pets-banner.png");
  background-position: center;
}
#exames .imagem-capa .login-capa {
  height: 100vh;
  width: auto;
}
#exames .conteudo-capa input {
  border-radius: 12px;
  border-color: #81A2B8;
  padding: 16px 0.75rem;
}
#exames .conteudo-capa label {
  font-weight: bold;
}
#exames .conteudo-capa .btn-banner {
  padding: 16px 20px;
  font-weight: bold;
}

#pag-index .banner .swiper .swiper-wrapper .swiper-slide {
  background-size: cover;
  height: 76vh;
}
#pag-index .banner .swiper .swiper-wrapper .swiper-slide .banner-img h1 {
  color: #fff;
}
#pag-index .oferecimento h1 {
  color: #85725A;
}
#pag-index .oferecimento p {
  color: #4a4444;
}
#pag-index .atendimento h1 {
  color: #fff;
}
#pag-index .atendimento h5 {
  color: #4a4444;
}
#pag-index .atendimento p {
  color: #4a4444;
}
#pag-index .faq h1 {
  color: #85725A;
}
#pag-index .faq h1 span {
  color: #057683;
}
#pag-index .faq .accordion .accordion-item {
  border: none;
  margin-top: 20px;
}
#pag-index .faq .accordion .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(0.7) !important;
}
#pag-index .faq .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}
#pag-index .galeria img {
  border-radius: 8px;
}