.img-login  {
    background-image: url('/img/pets-banner.png');
    object-fit: cover;
    background-size: cover;

}

@media (max-width: 600px)
{
  .img-login
   {
    background-image: none;
   }
}