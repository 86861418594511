.card-color-text {
    color: #6d6d6d !important;
}
.up-card {
    transform: translateY(-50px);
    z-index: 9;
}

.card {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 15px;
    height: 100%;
    padding: 0.5rem;

    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}



@media (max-width: 526px) {
    .card-unidades {
        .title-wrapper {
            width: 100%;
            .element-dog {
                max-width: 60px;
            }
            .title {
                font-size: 16px;
            }
        }
    }
}
@media (max-width: 600px) {
    .up-card {
        transform: translateY(0);
    }
}
