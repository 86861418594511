* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

a {
    text-decoration: none;
    outline: none;
}

ul {
    list-style: none;
}

main {
    margin-top: 4rem;
}

//Colors
:root {
    --white-HC-color: #ffffff;
    --orange-HC-color: #ef7a68;
    --green-HC-color: #057683;
    --egyptian-HC-Color: #f3a94c;
    --cheng-HC-color: #e39b2f;
    --light-HC-grey: #f7f7fa;
    --text-color: #85725A;
}

//keyframes
@keyframes puls {
    from {
        transform: scale(0.5);
        opacity: 0.5;
    }
    to {
        opacity: 1;
        transform: scale(0.9);
    }
}

// Helpers
.padding-space {
    padding: 3rem 1rem;
}


