

  // Section: Variables
  :root {
	--color-white: #fff;
	--color-light: hsl(206, 33%, 96%);
	--color-black: #000;
	--color-night: hsl(214, 100%, 10%);
	--color-purple: hsl(291, 64%, 42%);
	--color-indigo: #057683;

	--shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
}





.brand {

	&-link {
		font-family: inherit;
		font-size: 1rem;
		font-weight: 400;
		line-height: inherit;
		border: none;
		outline: none;
		color: var(--color-black);
		text-transform: uppercase;
		text-rendering: optimizeLegibility;

        & span{
            color: #98F3AC;
        }
	}
}

// Section: Navbar Menu
.navbar {
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	padding: 0.75rem 0;
	border: none;
	outline: none;
	color: var(--color-black);
	background: var(--color-white);
	box-shadow: var(--shadow-large);
    position: fixed;
    width: 100%;

	.wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: auto;
		margin: 0 auto;
	}

	.burger {
		position: relative;
		display: none;
		cursor: pointer;
		width: 2rem;
		height: 1rem;
		border: none;
		outline: none;
		opacity: 0;
		visibility: hidden;
		background: none;
		transform: rotate(0deg);
		transition: 0.35s ease-in-out;

		&-line {
			display: block;
			position: absolute;
			width: 100%;
			height: 2px;
			left: 0;
			border: none;
			outline: none;
			opacity: 1;
			border-radius: 0.25rem;
			background: var(--color-black);
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2),
			&:nth-child(3) {
				top: 0.5rem;
			}

			&:nth-child(4) {
				top: 1rem;
			}
		}

		&.active {
			.burger-line {
				&:nth-child(1),
				&:nth-child(4) {
					top: 1.25rem;
					width: 0%;
					left: 50%;
				}

				&:nth-child(2) {
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}
			}
		}
	}

	.menu {
        @media (max-width: 1199.98px){
            width: 100%;
        }

		&-inner {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
		}

        .menu-item {
            display: flex;
            align-items: center;
        }

		&-link {
			font-family: inherit;
            font-size: 14px;
			font-weight: 500;
			line-height: inherit;
			border: none;
			color: #AEAEAE;
			text-rendering: optimizeLegibility;
			transition: all 0.35s ease-in-out;
			padding: 10px;
            text-align: center;

			&.active,
			&:hover:not(.orange-color) {
				border: none;
				outline: none;
				color: var(--color-indigo);
				// background-image: url('/img/pata.png');
				background-repeat: no-repeat;

			}
		}
	}
}

// Section: Responsive Media Query
@media only screen and (max-width: 768px) {
	.navbar {
		.burger {
			display: block;
			opacity: 1;
			visibility: visible;
		}

		.menu {
			width: 100%;
			max-height: 0rem;
			padding: 0;
			opacity: 0;
			visibility: hidden;
			overflow: hidden;
			transition: all 0.35s ease;

			&.active {
				opacity: 1;
				visibility: visible;
			}

			&-inner {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 0.75rem;
				padding: 1rem 0;
			}
		}
	}
}
