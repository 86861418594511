.wrapper {
	height: 100%;
}

.block__item {
	margin-bottom: 20px;
}

.block__title {
	text-transform: uppercase;
	position: relative;
	padding-left: 30px;
	cursor: pointer;
}

.block__title::before,
.block__title::after {
	content: "";
	width: 10px;
	height: 1px;
	background-color: #000;
	position: absolute;
	top: 8px;
	transition: all 0.3s ease 0s;
}

.block__title:before {
	transform: rotate(40deg);
	left: 0;
}

.block__title::after {
	transform: rotate(-40deg);
	left: 8px;
}

.block__title.active::before,
.block__title.active::after {
	background-color: #ef7a68;
}

.block__title.active::before {
	transform: rotate(-40deg);
}

.block__title.active::after {
	transform: rotate(40deg);
}

.block__text {
	display: none;
	padding-top: 10px;
}

.faq-conteudo {
	.accordion-flush .accordion-item .accordion-button {
		border-radius: 10px;
	}
	.accordion-button {
		position: relative;
		display: grid;
		width: 100%;
		padding: 2rem 2.25rem;
		font-size: 1rem;
		color: #4A4444;
		font-family: "Poppins";
		text-align: left;
		background-color: #F5F6F6;
		border: 0;
		border-radius: 10px;
		overflow-anchor: none;
		transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
		flex-direction: row-reverse;
		align-items: center;
		align-content: stretch;

		&::after {
			flex-shrink: 0;
			position: absolute;
			width: 1.25rem;
			left: 12px;
			height: 1.25rem;
			margin-left: auto;
			content: "";
			background-repeat: no-repeat;
			background-size: 1.25rem;
			transition: transform .2s ease-in-out;
		}
	}
}