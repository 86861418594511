@mixin config-button {
    width: fit-content;
    gap: 16px;

    @media screen and (min-width: 768px) {
        width: 100%;
    }
}

.btn-banner {
    background-color: #057683;
    color: #fff;
    padding: 0.5rem 1rem;

    &:hover {
        color: #ef7a68;
        background-color: #fff;
        border: solid 1px #ef7a68;
        transition: all 0.35s ease;
    }
}

.btn-orange {
    background: #85725a;
    color: #fff;
    padding: 12px 48px;

    &:hover {
        background-color: #fff;
        color: #4a4444;
        border: solid 1px #85725a;
        transition: all 0.35s ease;
    }
}

.whatsapp-button {
    background-color: #ef7a68;
    color: white;
    border-radius: 50px;
    position: fixed;
    bottom: 21px;
    display: flex;
    padding: 10px 20px;
    right: 8px;
    text-align: center;
    color: var(--white-HC-color);
    z-index: 20;

    .btn {
        color: white;
        &:focus {
            box-shadow: none;
            color: white;
        }
    }

    .dropdown-menu.show {
        transform: translateY(-82px) !important;
        width: -webkit-fill-available;
        border: none;
    }

    img {
        width: 32px;
        margin-right: 10px;
    }

    span {
        font-size: 14px;
    }
}

.btn__redes {
    display: grid;
    bottom: 20px;
    left: 20px;
    position: fixed;
    z-index: 999;
}

.btn-i {
    @include config-button;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.087);
    padding: 1rem 2rem;
    border-radius: 30px;
    text-decoration: none;
    color: #c71e7e;
    display: flex;
    transition: all 0.2s ease-in-out;
    margin-bottom: 6px;

    iconify-icon {
        color: #df3796;
        font-size: 20px;
        transition: all 0.3s ease-in-out;
    }
    span {
        font-family: "Roboto", sans-serif;
        align-self: center;
        transform: translateX(0px);
        transition: all 0.1s ease-in-out;
        opacity: 1;
    }

    &:hover {
        transform: scale(1.1);
        background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

        iconify-icon {
            transform: translateX(0px);
            padding-right: 0;
            color: #fff;

            @media screen and (min-width: 768px) {
                transform: translateX(39px);
            }
        }
        span {
            transform: translateX(30px);
            opacity: 0;
        }
    }
    &:active {
        transform: scale(1);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
}

.btn-f {
    @include config-button;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.087);
    padding: 1rem 2rem;
    text-decoration: none;
    border-radius: 30px;
    color: #286ed6;
    display: flex;
    transition: all 0.2s ease-in-out;

    iconify-icon {
        color: #286ed6;
        font-size: 20px;
        transition: all 0.3s ease-in-out;
    }
    span {
        font-family: "Roboto", sans-serif;
        align-self: center;
        transform: translateX(0px);
        transition: all 0.1s ease-in-out;
        opacity: 1;
    }

    &:hover {
        transform: scale(1.1);
        background-color: #286ed6;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        iconify-icon {
            transform: translateX(0px);
            padding-right: 0;
            color: #fff;

            @media screen and (min-width: 768px) {
                transform: translateX(40px);
            }
        }
        span {
            transform: translateX(30px);
            opacity: 0;
        }
    }
    &:active {
        transform: scale(1);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
}

@media (max-width: 600px) {
    .btn-banner {
        width: 100% !important;
    }
}
