.bg-azul {
    background-image: url("/img/quem-somos/img-quem-somos.jpg");
}

.bg-cinza {
    background-color: #f7f7fa;
}

.bg-cinza-2 {
    background-color: #f5f6f6;
}

.bg-verde {
    background-color: #057683;
}

.bg-servicos {
    background-image: url("/img/laranja.png");
}

.bg-cat {
    background-image: url("/img/home/bg-cat.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 50vh;
    width: 100%;
    transform: translateY(80px);
    border-radius: 10px;
}

.bg-cardiac {
    background: #ef7a68;
    height: 50%;
}

.bg-footer {
    background-image: url("/img/footer/redes-sociais.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 10px;
}

@media (max-width: 1024px) {
    .bg-footer {
        background-image: none;
        background-color: #057683;
    }
}
