#exames {
  overflow: hidden; 

  .imagem-capa {
    background-image: url('/img/pets-banner.png');
    background-position: center;

    .login-capa {
      height: 100vh;
      width: auto;
    }
  }

  .conteudo-capa {
    input {
      border-radius: 12px;
      border-color: #81A2B8;
      padding: 16px 0.75rem;
    }

    label {
      font-weight: bold;
    }

    .btn-banner {
      padding: 16px 20px;
      font-weight: bold;
    }
  }
}