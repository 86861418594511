@import "@sass/components/navbar";
@import "@sass/components/reset";
@import "@sass/components/banner";
@import "@sass/components/cards";
@import "@sass/components/buttons";
@import "@sass/components/backgrounds";
@import "@sass/components/swiper";
@import "@sass/components/accordion";
@import "@sass/components/footer";
@import "@sass/components/login";
@import "@sass/components/colors";

#quem-somos {

    .title-carousel-unidades {
        padding: 1rem;
        border: 3px solid #e39b2f;
        border-radius: 45px;
        width: fit-content;
        font-family: "Mitr", sans-serif;
        color: #057683;
    }

    .swiper {
        width: 100%;
        height: 100%;

        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                background: #F7F7FA;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;

                &:nth-child(even){
                .card-bg {
                    background: transparent linear-gradient(180deg, #F8EDEB 0%, #F5C5BC 100%) 0% 0% no-repeat padding-box;
                    padding: 28px 28px 0px 28px;
                    border-radius: 12px;
                }
                }

                &:nth-child(odd){
                .card-bg {
                    background: transparent linear-gradient(180deg, #EBEBEB 0%, #B9BDCF 100%) 0% 0% no-repeat padding-box;
                    padding: 28px 28px 0px 28px;
                    border-radius: 12px;
                }
                }

                img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                }

                p {
                color: #4A4444;
                font-size: 16px;
                font-weight: normal;

                span {
                    text-align: left;
                    font-size: 16px;
                    font-weight: lighter;
                    font-family: "Poppins";
                    letter-spacing: 0px;
                    color: #85725A;
                }
                }
            }
        }

        &#infraestrutura {
            height: 300px;


            @media (max-width: 575.98px){
                height: 150px;
            }

            .swiper-button-next {
                width: 16px;
                height: 16px;
                &:after {
                border: none;
                color: #fff;
                padding: 12px;
                font-size: 20px;
                background-color: #057683;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                }
            }

            .swiper-button-prev {
                width: 16px;
                height: 16px;
                &:after {
                border: none;
                padding: 12px;
                color: #fff;
                font-size: 20px;
                background-color: #057683;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
                }
            }
        }

        &#profissional {
        .swiper-button-next {
            width: 16px;
            height: 16px;
            &:after {
            border: none;
            color: #fff;
            padding: 12px;
            font-size: 20px;
            background-color: #057683;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            }
        }

        .swiper-button-prev {
            width: 16px;
            height: 16px;
            &:after {
            border: none;
            padding: 12px;
            color: #fff;
            font-size: 20px;
            background-color: #057683;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            }
        }
        }
    }
}
