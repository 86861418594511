.banner {
  .swiper {
    height: 76%;
  }
}

.banner-img {
  background-size: cover;
  background-position: center;
  text-align: start;
}

.banner {
  .swiper-button-prev {
    background-color: #8A6B42;
    color: #fff;
    padding: 20px;
    left: -1px;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border: 1px solid #B1B1B1;
    
    &::after  {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .swiper-button-next {
    background-color: #8A6B42;
    color: #fff;
    padding: 20px;
    right: -1px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border: 1px solid #B1B1B1;
    
    &::after  {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.background-settings {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-top-settings {
  padding: 2rem 1rem;

  .subtitle {
    font-size: 18px;
  }
}

.banner-unidades {
  height: 315px;
  display: flex;
  align-items: center;
}

.bg-color-grey {
  background-color: #f4f4f491;
}


@media (max-width: 500px) {
  .banner  {
    .swiper-button-next {
      display: none;
    }
    .swiper-button-prev {
      display: none;
    }
  }
}