@import "@sass/components/navbar";
@import "@sass/components/reset";
@import "@sass/components/banner";
@import "@sass/components/cards";
@import "@sass/components/buttons";
@import "@sass/components/backgrounds";
@import "@sass/components/swiper";
@import "@sass/components/accordion";
@import "@sass/components/footer";
@import "@sass/components/login";
@import "@sass/components/colors";

.paw-list img {
    width: 2rem;
}

.img-gallery{
    display:flex;
    gap: 0.5rem;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    a {
        img{
            max-height: 20rem;
            width: 100%;
        }
    }
}

.setas-baixo {
    transform: translateY(100px);
    width: 6rem;
}

.setas-cima {
    transform: translateY(50px);
    width: 6rem;
}

// PAGE SERVICOS
.servicos-wrapper {
    // background-image: url(/img/backgrounds/bg-element-animals.png);
    background-repeat: no-repeat;
    background-position: 60px;
    background-color: #fff;
}

//DASHBOARD
.dashboard {
    background-image: url(/img/backgrounds/bg-element-animals.png);
    background-repeat: no-repeat;
    background-position: 60px;
    background-color: var(--light-HC-grey);

    .dashboard-header {
        padding: 1rem;
        border-radius: 8px;
        background-color: var(--white-HC-color);
        box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
        margin-bottom: 2rem;

        .user-name-title {
            font-size: 18px;
            color: var(--green-HC-color);
            font-family: "Mitr", sans-serif;
            margin-bottom: 0;
            margin-right: 17px;
            word-break: break-word;
            text-align: center;
            position: relative;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 2px;
                height: 28px;
                top: 0;
                right: -7px;
                background-color: var(--green-HC-color);
            }
        }

        .user-button-logout {
            background-color: var(--egyptian-HC-Color);
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 5px;
            color: var(--white-HC-color);
            transition: all 0.3s ease;
            cursor: pointer;
            &:hover {
                background-color: var(--green-HC-color);
            }
            .fa-sign-out-alt {
                font-size: 20px;
            }
        }
    }

    .accordion {
        .accordion-item {
            box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
            border-radius: 8px;
            .accordion-header {
                .accordion-button {
                    background-color: var(--orange-HC-color);
                    color: var(--white-HC-color);
                    border-radius: 8px;
                    &:focus {
                        border-color: #ef7a68;
                        box-shadow: 0 0 0 0.25rem #ef7a6840;
                    }
                }
                .accordion-button::after {
                    display: none;
                }
            }

            .accordion-body {
                .description-wrapper {
                    padding: 1rem;
                    border-bottom: 1px solid #f3a94c;
                }
                .information-wrapper {
                    padding: 1rem;

                    .title {
                        font-size: 22px;
                        color: var(--green-HC-color);
                        font-family: "Mitr", sans-serif;
                        text-align: center;
                    }
                    .data {
                        color: var(--green-HC-color);
                        margin-bottom: 0;
                        padding: 5px 10px;
                        background-color: #05768321;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .link-wrapper {
                        color: var(--green-HC-color);
                        margin-bottom: 1rem;
                        padding: 5px 10px;
                        background-color: #05768321;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;

                        a {
                            word-break: break-word;
                            color: var(--orange-HC-color);
                        }
                    }

                    .file-wrapper {
                        background-color: var(--light-HC-grey);
                        padding: 10px;
                        border-radius: 5px;

                        .file-content {
                            width: fit-content;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
                            border-radius: 5px;
                            padding: 1rem;
                            background-color: var(--white-HC-color);
                            img {
                                width: 60px;
                            }
                            .btn-download {
                                background-color: var(--egyptian-HC-Color);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .img-gallery{
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .dashboard {
        .dashboard-header {
            .user-name-title {
                &::before {
                    display: none;
                }
            }
        }
    }
}
