#pag-index {
  .banner {
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          background-size: cover;
          height: 76vh;

          .banner-img {
            h1 {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .oferecimento {
    h1 {
      color: #85725A;
    }
    p {
      color: #4a4444;
    }
  }

  .atendimento {
    h1 {
      color: #fff;
    }
    h5 {
      color: #4a4444;
    }
    p {
      color: #4a4444;
    }
  }

  .faq {
    h1 {
      color: #85725A;

      span {
        color: #057683;
      }
    }

    .accordion {

      .accordion-item {
        border: none;
        margin-top: 20px;
      }

      .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
        transform: scale(.7) !important;
        }
        .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
        }
    }
  }

  .galeria {
    img {
      border-radius: 8px;
    }
  }
}