@import "@sass/components/navbar";
@import "@sass/components/reset";
@import "@sass/components/banner";
@import "@sass/components/cards";
@import "@sass/components/buttons";
@import "@sass/components/backgrounds";
@import "@sass/components/swiper";
@import "@sass/components/accordion";
@import "@sass/components/footer";
@import "@sass/components/login";
@import "@sass/components/colors";


#unidades {
    overflow: hidden;
  .banner-top-settings {
    background-image: url('/img/unidades/banner.jpg');

    h1 {
      color: #057683;
    }
    p {
      color: #9A9A9A;
    }
  }

  .unidades-wrapper {
    // background-image: url(/img/backgrounds/bg-element-animals.png);
    background-repeat: no-repeat;
    background-position: 60px;
    background-color: var(--white-HC-color);

    .swiper {
        width: 100%;
        height: 100%;
        background-color: var(--white-HC-color);
    }

    .swiper-button-prev {
        background-image: url('/img/icones/button-prev-swiper.png');
        background-repeat: no-repeat;
        height: 70px;
        width: 65px;
        transition: all 0.3s ease;
        &:after {
            display: none;
        }
        &:hover {
            transform: translateX(-5px);
        }
    }

    .swiper-button-next {
        background-image: url('/img/icones/button-next-swiper.png');
        background-repeat: no-repeat;
        height: 70px;
        width: 55px;
        transition: all 0.3s ease;
        &:after {
            display: none;
        }
        &:hover {
            transform: translateX(5px);
        }
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: var(--white-HC-color);

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper {
        width: 100%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--white-HC-color);
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }

    .mySwiperUnidades {
        height: 290px;
        width: 100%;
        margin-bottom: 1rem;
        border-radius: 20px 20px 0 0;
    }

    .mySwipethumbs {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
        background-color: var(--white-HC-color);
    }

    .mySwiperthumbs .swiper-slide {
        width: 25%;
        height: 100px;
        opacity: 0.4;
    }

    .mySwiperthumbs .swiper-slide-thumb-active {
        opacity: 1;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }

  .card-unidades {
    padding: 2rem;
    background-color: var(--white-HC-color);
    border-radius: 20px;

    &:hover {
      .title-wrapper {
        border: 3px solid #e39b2f;
      }
    }

    .title-wrapper {
      border-radius: 90px;
      border: 3px solid #057683;
      padding: 12px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      width: 85%;

      .title {
        margin-bottom: 0;
        color: var(--green-HC-color);
        font-size: 26px;
        font-family: "Mitr", sans-serif;

        @media (max-width: 575.98px){
            font-size: 16px;
        }
      }
    }

    .content-wrapper {
      a, p {
        color: #818181;
        word-break: break-all;
        font-size: 16px;

        &:hover {
          color: var(--cheng-HC-color);
        }
      }
    }
  }
}
